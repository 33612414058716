import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export function selectHypothesisFrame(state: RootState) {
  return state.messages.hypothesisFrame;
}

const selectMessages = (state: RootState) => state.messages;
const selectIsAIActive = createSelector(
  selectMessages,
  (messages) => messages.isAIActive
);
const selectIsWebSocketConnected = createSelector(
  selectMessages,
  (messages) => messages.isConnected
);
const selectIsNewSession = createSelector(
  selectMessages,
  (messages) => messages.isNewSession
);
const selectRestaurantStaffIntervention = createSelector(
  selectMessages,
  (messages) => messages.restaurantStaffIntervention
);
const selectIsStaffIntervention = createSelector(
  selectMessages,
  (messages) => messages.isStaffIntervention
);
const selectIsPlaying = createSelector(
  selectMessages,
  (messages) => messages.isPlaying
);
const selectAsrcPlayer = createSelector(
  selectMessages,
  (messages) => messages.asrcPlayer
);
const selectCurrentSessionId = createSelector(
  selectMessages,
  (messages) => messages.currentSessionId
);
const selectIsStaffIntervenedBeforeCarExit = createSelector(
  selectMessages,
  (messages) => messages.isStaffIntervenedBeforeCarExit
);

export {
  selectAsrcPlayer,
  selectCurrentSessionId,
  selectIsAIActive,
  selectIsNewSession,
  selectIsPlaying,
  selectIsStaffIntervenedBeforeCarExit,
  selectIsStaffIntervention,
  selectIsWebSocketConnected,
  selectRestaurantStaffIntervention,
};
