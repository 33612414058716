import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import useActivityTracking from '../../hooks/useActivityTracking.hooks';
import { selectIsReasonsDialogOpen } from '../../redux/features/ai/ai.selector';
import InterceptionReasonsDialog from '../InterceptionReasonsDialog';
import MainContent from '../MainContent';
import RestaurantStaffDialog from '../RestaurantStaff';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  bodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
  },
}));

const Body = () => {
  const { bodyClass } = useStyles();
  const isReasonsDialogOpen = useAppSelector(selectIsReasonsDialogOpen);
  useActivityTracking();

  return (
    <div data-testid="body" className={bodyClass}>
      <MainContent />
      <RestaurantStaffDialog />
      {isReasonsDialogOpen && <InterceptionReasonsDialog />}
    </div>
  );
};

export default Body;
