import { useAppSelector } from '../../app/hooks';
import { AIStatusColors } from '../../redux/features/ai/ai.constants';
import {
  selectAIStatus,
  selectIsAIEscalation,
} from '../../redux/features/ai/ai.selector';
import { selectActiveRestaurantIsUnsupervisedAI } from '../../selectors/restaurant';
import useIntervention from '../intervention/useIntervention.hooks';

const useUnsupervisedAI = () => {
  const isRestaurantUnsupervisedAI = useAppSelector(
    selectActiveRestaurantIsUnsupervisedAI
  );
  const aiStatus = useAppSelector(selectAIStatus);
  const isAIEscalation = useAppSelector(selectIsAIEscalation);
  const { isStaffInterventionHappening } = useIntervention();

  const isAIStatusGreen = aiStatus.status === AIStatusColors.green;
  const showUnsupervisedAIOverlay =
    isRestaurantUnsupervisedAI &&
    !isAIEscalation &&
    !isStaffInterventionHappening &&
    isAIStatusGreen;

  return { isRestaurantUnsupervisedAI, showUnsupervisedAIOverlay };
};

export default useUnsupervisedAI;
