import { Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  loadingIndicatorClass: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loadingBoxClass: {
    display: 'flex',
    width: '100%',
    height: spacing(40),
    alignItems: 'center',
    flexDirection: 'column',
  },
  restaurantNameClass: {
    height: spacing(4),
  },
}));

type LoadingIndicatorProps = {
  restaurantName: string;
  isFetchingTaskStatus: boolean;
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  restaurantName,
  isFetchingTaskStatus,
}) => {
  const { loadingIndicatorClass, loadingBoxClass, restaurantNameClass } =
    useStyles();

  return (
    <div data-testid="loading-indicator" className={loadingIndicatorClass}>
      <Box className={loadingBoxClass}>
        <Typography className={restaurantNameClass} variant="h5" gutterBottom>
          {!isFetchingTaskStatus ? restaurantName : ''}
        </Typography>
        <CircularProgress />
        <Typography variant="h4" gutterBottom>
          {isFetchingTaskStatus
            ? 'Task Assignment in Progress'
            : 'Switching restaurant...'}
        </Typography>
        <Typography>Please wait, this might take a moment</Typography>
      </Box>
    </div>
  );
};

export default LoadingIndicator;
