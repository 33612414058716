import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { infoSeqIdCounter } from '../app/middleware';
import { DUMMY_RESTAURANT_CODE, TIMEOUT } from '../constants';
import { AgentTypes, EventTypes } from '../constants/event';
import {
  IHITLEventConnectionTransmissionMessage,
  messagingActions,
  sendTestSignal,
} from '../reducers/messagingSlice';
import {
  selectEventAPI,
  selectEventAPIAuth,
} from '../redux/features/config/config.selector';
import { selectSessionId } from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectIsWebSocketConnected } from '../selectors/message';
import { selectActiveRestaurantCode } from '../selectors/restaurant';
import { selectUserProfile } from '../selectors/user';
import { UserDetails } from '../types';
import { post } from '../utils/api';
import logger from '../utils/logger';
import useUnsupervisedAI from './unsupervisedAI/useUnsupervisedAI.hooks';

interface IAgentActivity {
  activityType: string;
  event?: EventTypes;
}
const useActivityTracking = () => {
  const isWebSocketConnected = useAppSelector(selectIsWebSocketConnected);
  const userDetails = useAppSelector(selectUserProfile);
  const sessionId = useAppSelector(selectSessionId);
  const restaurantCode = useAppSelector(selectActiveRestaurantCode);
  const eventAPI = useAppSelector(selectEventAPI);
  const eventAPIAuth = useAppSelector(selectEventAPIAuth);
  const [isFirstActivity, setIsFirstActivity] = useState<boolean>(true);
  const { showUnsupervisedAIOverlay } = useUnsupervisedAI();
  const dispatch = useAppDispatch();

  let agentId = uuidv4();
  const successCallback = (response: any) => {
    logger.debug({
      message: 'Post message to Event API successfully',
      moreInfo: JSON.stringify(response),
    });
  };

  const errorCallback = (error: any) => {
    logger.error({
      message: 'Failed to send message to Event API',
      error,
    });
  };

  const sendAgentActivity = async (agentActivity: IAgentActivity) => {
    const { id, username, email, firstName, lastName } =
      (userDetails as UserDetails) || {};

    const { activityType, event = EventTypes.agentActivity } = agentActivity;
    const payload = {
      id: uuidv4(),
      seq: infoSeqIdCounter.increment(),
      agent_id: agentId,
      agent_type: AgentTypes.HITL,
      session_id: sessionId || 'dummy_session_id',
      timestamp: new Date().toISOString(),
      event,
      data: {
        user_details: {
          id,
          username,
          email,
          firstName,
          lastName,
        },
        activity_type: activityType,
      },
      restaurant_code: restaurantCode || DUMMY_RESTAURANT_CODE,
    };

    post({
      url: `${eventAPI}/v1/event`,
      data: {
        ...payload,
      },
      headers: {
        Authorization: `Basic ${eventAPIAuth}`,
      },
      successCallback,
      errorCallback,
    });
  };

  const onAction = (event?: Event) => {
    if (!showUnsupervisedAIOverlay) {
      if (isFirstActivity) {
        sendAgentActivity({
          activityType: event?.type || 'Event',
          event: EventTypes.agentFirstActivity,
        });

        setIsFirstActivity(false);
      } else if (!isWebSocketConnected) {
        sendAgentActivity({
          activityType: event?.type || 'Event',
        });
      }
    }
  };

  const { pause } = useIdleTimer({
    onAction,
    throttle: 500,
  });

  useEffect(() => {
    if (isWebSocketConnected) {
      if (isFirstActivity) {
        const payload: Partial<IHITLEventConnectionTransmissionMessage> = {
          data: {
            message: 'Websocket connection to event backend is established',
          },
        };
        dispatch(messagingActions.sendHITLEventConnection(payload as any));
      } else {
        let timer = setTimeout(() => {
          pause(); // Stop sending agent activity after 5000 ms after websocket connection is established
        }, TIMEOUT);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [isWebSocketConnected, isFirstActivity]);

  useEffect(() => {
    dispatch(sendTestSignal());
  }, []);
};

export default useActivityTracking;
