import { AIStatusColors, DEFAULT_STATUS_MESSAGE } from './ai.constants';
import { IAIState } from './ai.props';

const initialState: IAIState = {
  isAIAutoMode: true, // Indicates whether AI order has been going smoothly or intercepted
  isAIEscalation: false,
  aiStatus: {
    status: AIStatusColors.green,
    message: DEFAULT_STATUS_MESSAGE,
  },
  interceptionReasonsDialogOpen: false,
};

export { initialState };
