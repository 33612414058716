import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuStages } from '../constants/enums';
import { CartItemAdditionTypes } from '../constants/event';
import {
  Category,
  CategoryAndTimePeriodQueryQuery,
  MenuItem,
  MenuItemSetting,
  MenuItemsQueryQuery,
  MenuOverride,
  ModalityType,
  ModifierGroup,
  ModifierGroupsQueryQuery,
  PosProperties,
  TimePeriod,
} from '../generated-interfaces/graphql';
import { startLoading } from '../redux/features/loading/loading.slice';
import { IUnavailableItem } from '../types';
import { defaultMenuVersion } from '../utils/constants';
import {
  getModifierAndModGroupFromHypotheses,
  getModifierAndModGroupFromHypothesesByName,
} from '../utils/hypotheses';
import logger from '../utils/logger';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import {
  buildFullMenuItem,
  considerTimePeriodCategory,
  fetchMenuBasedOnStage,
  formatCategoryAndTimePeriodResponse,
  formatMenuResponse,
  formatModGroupResponse,
  IMenuVersion,
  IMenuVersionsResponse,
  MenuResponses,
  parseCategoryAndTimeperiodResponse,
  ParsedCategory,
  ParsedMenuItem,
  parseMenuResponse,
  PersistentMenuProperty,
  TopLevelMenuItem,
} from '../utils/menu';
import { getMenuVersionsFromMenuAPI } from '../utils/network';
import { GenericMap } from '../utils/types';
import { cartActions } from './cartSlice';
import { dialogActions } from './dialogSlice';
import {
  EntityMenuItem,
  ErrorTransmissionMessage,
  messagingActions,
} from './messagingSlice';

export interface MenuState {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  availableCategoryWithTimePeriod: ParsedCategory[];
  categoriesWithTimePeriod: ParsedCategory[];
  alwaysAvailableCategories: ParsedCategory[];
  menuRes?: MenuResponses;
  fullMenuItems: GenericMap<ParsedMenuItem>;
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  codeNameMapping: ModSymbolCodeNameMappingType;
  menuVersions: IMenuVersion[];
  selectedMenuVersion: string;
  prodLiveVersion?: IMenuVersion;
  unavailableItems: Record<string, IUnavailableItem>;
}

export interface IAddItemToCart {
  menuItem: TopLevelMenuItem;
  quantity?: number;
  addedBy?: CartItemAdditionTypes;
  prefixWord?: string;
  inputModSymbol?: string;
}

export interface IProcessedCategoryAndTimePeriodJSON {
  categories: GenericMap<Category>;
  timePeriods: GenericMap<TimePeriod>;
}

export interface IProcessedMenuJSON {
  menuItems: GenericMap<MenuItem>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posSettings: GenericMap<PosProperties>;
}

export interface IProcessedModGroupsJSON {
  modifierGroups: GenericMap<ModifierGroup>;
}
export interface IUpdateMenuItems {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  processedCategoryAndTimePeriodJSON: GenericMap<IProcessedCategoryAndTimePeriodJSON>;
  processedMenuJSON: GenericMap<IProcessedMenuJSON>;
  processedModGroupsJSON: GenericMap<IProcessedModGroupsJSON>;
  categoriesWithTimePeriod: ParsedCategory[];
  availableCategoryWithTimePeriod: ParsedCategory[];
  alwaysAvailableCategories: ParsedCategory[];
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  codeNameMapping: ModSymbolCodeNameMappingType;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  unavailableItems: Record<string, IUnavailableItem>;
}

export const initialState: MenuState = {
  topLevelMenuItems: {},
  availableCategoryWithTimePeriod: [],
  categoriesWithTimePeriod: [],
  alwaysAvailableCategories: [],
  menuRes: undefined,
  fullMenuItems: {},
  persistentVoiceProps: {},
  modSymbolMapping: {},
  codeNameMapping: {},
  menuVersions: [],
  selectedMenuVersion: 'latest',
  prodLiveVersion: {} as IMenuVersion,
  unavailableItems: {},
};

/**
 * This function takes the menu data returned from APIs and make it in usable fashion to be stored in redux state
 */
const processMenuResponse = ({
  categoryAndTimePeriodJSON,
  menuJSON,
  modifierGroupJSON,
  persistentMenuProperty,
  unavailableItems,
  timezone,
  currentStage,
  getState,
}: {
  categoryAndTimePeriodJSON: CategoryAndTimePeriodQueryQuery | {};
  menuJSON: MenuItemsQueryQuery | {};
  modifierGroupJSON: ModifierGroupsQueryQuery | {};
  persistentMenuProperty: PersistentMenuProperty[];
  unavailableItems: Record<string, IUnavailableItem>;
  timezone: string;
  currentStage: string;
  getState: Function;
}) => {
  const {
    restaurant: { selectedRestaurantCode } = { selectedRestaurantCode: '' },
  } = getState();
  let persistentVoiceProps = {};
  try {
    if (persistentMenuProperty?.length > 0) {
      Object.assign(
        persistentVoiceProps,
        (persistentMenuProperty as PersistentMenuProperty[]).reduce(
          (acc, entry) => {
            acc[entry.unique_identifier] = entry;
            return acc;
          },
          {} as GenericMap<PersistentMenuProperty>
        )
      );
    }
  } catch (error) {
    logger.error({
      restaurantCode: selectedRestaurantCode,
      message: 'Failed while processing persistent menu properties',
      error,
    });
  }

  const processedCategoryAndTimePeriodJSON =
    formatCategoryAndTimePeriodResponse(
      categoryAndTimePeriodJSON as CategoryAndTimePeriodQueryQuery
    );

  const { categoriesWithTimePeriod, alwaysAvailableCategories } =
    parseCategoryAndTimeperiodResponse(processedCategoryAndTimePeriodJSON);

  const availableCategoryWithTimePeriod = timezone
    ? considerTimePeriodCategory(categoriesWithTimePeriod, timezone)
    : categoriesWithTimePeriod;

  const processedMenuJSON = formatMenuResponse(menuJSON as MenuItemsQueryQuery);

  const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
    parseMenuResponse({
      categories: [
        ...availableCategoryWithTimePeriod,
        ...alwaysAvailableCategories,
      ],
      menuRes: processedMenuJSON,
      persistentVoiceProps,
      unavailableItems,
      stage: currentStage,
    });

  const modality = getState().cart.modality;

  return {
    modality,
    topLevelMenuItems,
    processedCategoryAndTimePeriodJSON,
    processedMenuJSON,
    processedModGroupsJSON: formatModGroupResponse(
      modifierGroupJSON as ModifierGroupsQueryQuery
    ),
    categoriesWithTimePeriod,
    availableCategoryWithTimePeriod,
    alwaysAvailableCategories,
    persistentVoiceProps,
    codeNameMapping,
    modSymbolMapping,
    unavailableItems,
  };
};

export const fetchMenu = createAsyncThunk(
  'menu/getMenu',
  async (
    {
      restaurantCode,
      timezone,
      currentMenuVersion,
      currentStage,
      taskRouterMenuLoadFailureCallback,
      taskRouterMenuLoadSuccessCallback,
    }: {
      restaurantCode: string;
      timezone?: string;
      currentMenuVersion: string;
      currentStage: MenuStages;
      taskRouterMenuLoadFailureCallback?: Function;
      taskRouterMenuLoadSuccessCallback?: Function;
    },
    { getState, dispatch }
  ) => {
    try {
      const {
        categoryAndTimePeriodJSON,
        menuJSON,
        modifierGroupJSON,
        persistentMenuProperty,
        unavailableItems,
      } = await fetchMenuBasedOnStage({
        restaurantCode,
        state: getState(),
        currentMenuVersion,
        currentStage,
        dispatch,
      });

      const processedMenu = processMenuResponse({
        categoryAndTimePeriodJSON,
        menuJSON,
        modifierGroupJSON,
        persistentMenuProperty,
        unavailableItems,
        timezone: timezone || '',
        currentStage,
        getState,
      });
      dispatch(updateMenuItems(processedMenu));
      taskRouterMenuLoadSuccessCallback?.();
    } catch (error) {
      const {
        cachedMenu: { menusById },
        taskRouter: { currentTaskId },
        order: { currentSessionId: orderSessionId },
        messages: { currentSessionId: messageSessionId },
      } = getState();

      logger.error({
        restaurantCode,
        message: 'Get Menu Items From Restaurant Portal Failed',
        isTaskFromTaskRouter: !!currentTaskId,
        error: JSON.stringify(error),
      });

      //If fetching the menu of a restaurant fails, as a fallback mechanism the following things are done when task is arrived via task-router
      // - Old menu cache of restaurant exists => use the old version menu
      // - Old menu cache of restaurant does not exists => trigger an intervention with reason code, empty the menu, alert of menu load error(5 seconds), take them to the task router lobby

      if (currentTaskId) {
        //Task arrived via task router
        if (menusById?.[restaurantCode]) {
          //Old menu cache exists for this restaurant
          logger.error({
            restaurantCode,
            message: 'Using old cache of the restaurant',
            isTR: true,
            error,
          });
          const {
            categoryAndTimePeriodJSON,
            menuJSON,
            modifierGroupJSON,
            persistentMenuProperty,
            unavailableItems,
          } = menusById[restaurantCode];
          const processedMenu = processMenuResponse({
            categoryAndTimePeriodJSON,
            menuJSON,
            modifierGroupJSON,
            persistentMenuProperty,
            unavailableItems,
            timezone: timezone || '',
            currentStage,
            getState,
          });
          dispatch(updateMenuItems(processedMenu));
          taskRouterMenuLoadSuccessCallback?.();
        } else {
          //No old cache exists for the restaurant
          logger.error({
            restaurantCode,
            message: 'No old cache exists for the restaurant',
            isTR: true,
            error,
          });
          taskRouterMenuLoadFailureCallback?.({
            restaurantCode,
            orderSessionId,
            messageSessionId,
          });
        }
      }
    }
  }
);

export const addItemToCart = createAsyncThunk(
  'menu/addItemToCart',
  async (
    {
      menuItem,
      quantity,
      addedBy = CartItemAdditionTypes.human,
      prefixWord,
      inputModSymbol,
    }: IAddItemToCart,
    { dispatch, getState }
  ) => {
    const {
      cart: { modality, sequenceId: cartSequenceId },
      menu: { menuRes, persistentVoiceProps, modSymbolMapping },
    } = getState();

    if (menuRes) {
      const parsedMenuItem = buildFullMenuItem(
        menuItem,
        menuRes,
        persistentVoiceProps
      );
      dispatch(
        cartActions.addItemToCart({
          ...parsedMenuItem,
          cartItemId: cartSequenceId,
          childModifierGroups: {},
          modality,
          modcode: menuItem.modcode,
          addedBy,
        })
      );
      if (quantity) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: String(cartSequenceId),
            quantity,
          })
        );
      }

      if (prefixWord) {
        // handle prefixWord by selecting it as modifier if it is found
        Object.values(parsedMenuItem.modifierGroups).every((modGroup) => {
          const target = Object.values(modGroup.menuItems).find(
            (menuItem) =>
              menuItem.name.toLowerCase() === prefixWord.toLowerCase()
          );
          if (target) {
            dispatch(
              cartActions.selectModifier({
                cartItemId: cartSequenceId,
                menuItem: target,
                modGroup: modGroup,
                selected: true,
                modCode: inputModSymbol || '',
                modSymbolMapping,
                posSettings: menuRes.posSettings,
              })
            );
            return false;
          }
          return true;
        });
      }

      dispatch(setFullMenuItem(parsedMenuItem));
    }
  }
);

export const addHypothesisItemToCart = createAsyncThunk(
  'menu/addHypothesisItemToCart',
  async (orderItem: EntityMenuItem, { dispatch, getState }) => {
    const {
      menu: { topLevelMenuItems, modSymbolMapping, codeNameMapping, menuRes },
      cart: { sequenceId: cartSequenceId },
    } = getState();

    const handleOrderItemChildren = (
      children: EntityMenuItem[],
      fullMainItem: ParsedMenuItem,
      currentCartItemId: number
    ) => {
      children.forEach((mod) => {
        //find the modifier group and modifier based on the modifier id
        let { modGroup, modifier } = getModifierAndModGroupFromHypotheses(
          fullMainItem,
          String(mod.id),
          mod.modifier_group_id
        );

        if (!modGroup || !modifier) {
          //find the modifier group and modifier based on the modifier name if they couldn't be found by id
          logger.error(
            `Can't find the modifier ${mod.id} or modifier group ${mod.modifier_group_id} based on the ids from the hypotheses in the menu`
          );

          const { modGroup: modGroupByName, modifier: modifierByName } =
            getModifierAndModGroupFromHypothesesByName(
              fullMainItem,
              mod.name,
              mod.modifier_group_id
            );
          modGroup = modGroupByName;
          modifier = modifierByName;
        }

        if (modifier && modGroup) {
          const modCode = mod.pos_specific_properties.modcode
            ? codeNameMapping[mod.pos_specific_properties.modcode].code
            : '';

          //TODO handle the quantity of modifier when we have the quantity selection function in the future. Now the quantity should default to 1.
          dispatch(
            cartActions.selectModifier({
              cartItemId: currentCartItemId,
              menuItem: modifier,
              modGroup,
              selected: true,
              modCode,
              modSymbolMapping,
              posSettings: menuRes?.posSettings || {},
            })
          );

          // Handle nested modifier
          if (mod.children.length > 0) {
            handleOrderItemChildren(mod.children, modifier, currentCartItemId);
          }
        } else {
          logger.error(
            `Can't find the modifier ${mod.name} with id ${mod.id} or modifier group ${mod.modifier_group_id} based on the id or name from the hypotheses in the menu`
          );
          const errorMessage = `can't find the modifier ${mod.name} with id ${mod.id} or modifier group ${mod.modifier_group_id} based on the ids from the hypothesis in the menu`;
          const payload: Partial<ErrorTransmissionMessage> = {
            data: { message: errorMessage },
          };
          dispatch(messagingActions.sendError(payload as any));
        }
      });
    };

    //use the first menu item with the id in the assumption of the same item will show up in the menu once
    let mainItem = Object.values(topLevelMenuItems).find(
      (menuItem) => String(orderItem.id) === menuItem.id
    );

    if (!mainItem) {
      // Find the item in menu by name if it couldn't be found by id
      logger.error(
        `Can't find the main item based on the id: ${orderItem.id} from the hypotheses in the menu`
      );

      mainItem = Object.values(topLevelMenuItems).find(
        (menuItem) => orderItem.name === menuItem.name
      );
    }

    if (mainItem) {
      let currentCartItemId = cartSequenceId;
      const inputModSymbol = orderItem.pos_specific_properties.modcode
        ? codeNameMapping[orderItem.pos_specific_properties.modcode].code
        : '';

      //add the main item to cart
      await dispatch(
        addItemToCart({
          menuItem: mainItem,
          quantity: orderItem.quantity,
          addedBy: CartItemAdditionTypes.AI,
          inputModSymbol,
        })
      );

      const {
        menu: { fullMenuItems },
      } = getState();

      const itemId = mainItem.id + '-' + mainItem.categoryId;
      const fullMainItem = fullMenuItems[itemId];

      const children = orderItem.children;
      if (children?.length > 0) {
        handleOrderItemChildren(children, fullMainItem, currentCartItemId);
      }

      let quantity = orderItem.quantity ? Number(orderItem.quantity) : 1;

      if (quantity > 1) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: currentCartItemId.toString(),
            quantity: Math.min(quantity, 19),
          })
        );
      }
      dispatch(
        dialogActions.updateSelectedItem({
          item: fullMainItem,
          itemCartId: currentCartItemId,
        })
      );
      dispatch(dialogActions.setModGroupTabIndex(0));
      dispatch(dialogActions.updateSelectedModGroup());
    } else {
      logger.error(
        `Can't find the main item based on the id: ${orderItem.id} or name: ${orderItem.name} from the hypotheses in the menu`
      );
      const errorMessage = `can't find the main item ${orderItem.id} based on the id from the hypothesis in the menu`;
      const payload: Partial<ErrorTransmissionMessage> = {
        data: { message: errorMessage },
      };
      dispatch(messagingActions.sendError(payload as any));
    }
  }
);

export const fetchMenuVersions = createAsyncThunk(
  'menu/menuVersions',
  async (
    { restaurantCode: restaurant_code }: { restaurantCode: string },
    { getState }
  ) => {
    const {
      config: { MENU_API },
    } = getState();
    let filteredMenuVersions: IMenuVersion[] = [];
    let prodLiveVersion = {} as IMenuVersion;
    if (restaurant_code) {
      const menuVersions: { data: IMenuVersionsResponse[] } =
        await getMenuVersionsFromMenuAPI(MENU_API, {
          restaurant_code,
        });
      const { LIVE, PLAYGROUND } = MenuStages;
      const versionMapping = (menuVersions?.data || []).reduce(
        (
          list,
          {
            commit_id: commitId,
            created_at: createdAt,
            creator_first_name,
            creator_last_name,
            publisher_username: publisherUsername,
            publisher_first_name,
            publisher_last_name,
            creator_username: creatorUsername,
            id,
            stage,
            is_active: isActive,
            updated_at: updatedAt,
            comment,
          }
        ) => {
          const versionData = {
            commitId,
            createdAt,
            creatorUsername,
            creatorName: `${creator_first_name} ${creator_last_name}`,
            publisherUsername,
            publisherName: `${publisher_first_name} ${publisher_last_name}`,
            id,
            stage,
            isActive,
            updatedAt,
            comment,
          };
          if (stage.toUpperCase() === LIVE && isActive) {
            prodLiveVersion = versionData;
          }
          if (stage.toUpperCase() === PLAYGROUND) {
            list[commitId] = versionData;
          }
          return list;
        },
        {} as Record<string, IMenuVersion>
      );
      filteredMenuVersions = Object.values(versionMapping).sort(
        (a, b) => Number(b.commitId) - Number(a.commitId)
      );
    }
    return {
      menuVersions: [
        Object.assign({}, defaultMenuVersion),
        ...filteredMenuVersions,
      ],
      prodLiveVersion,
    };
  }
);

export const selectMenuVersion = createAsyncThunk(
  'menu/selectMenuVersion',
  async (currentMenuVersion: string, { getState, dispatch }) => {
    const {
      restaurant: {
        selectedRestaurantDetails: { timezone, restaurantCode },
        selectedStage: currentStage,
      },
    } = getState();

    if (restaurantCode) {
      dispatch(startLoading({}));
      dispatch(
        fetchMenu({
          currentMenuVersion,
          timezone,
          restaurantCode,
          currentStage,
        })
      );
    }
    return currentMenuVersion;
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    considerTimePeriods: (
      state,
      action: PayloadAction<{
        modality: ModalityType;
        timezone: string;
        stage: string;
      }>
    ) => {
      if (state.categoriesWithTimePeriod.length && state.menuRes) {
        const { timezone, stage } = action.payload;
        state.availableCategoryWithTimePeriod = [
          ...considerTimePeriodCategory(
            state.categoriesWithTimePeriod,
            timezone
          ),
        ];
        const { menuItems, overrides, menuItemSettings, posSettings } =
          state.menuRes;
        const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
          parseMenuResponse({
            categories: [
              ...state.alwaysAvailableCategories,
              ...state.availableCategoryWithTimePeriod,
            ],
            menuRes: { menuItems, overrides, menuItemSettings, posSettings },
            persistentVoiceProps: state.persistentVoiceProps,
            unavailableItems: state.unavailableItems,
            stage,
          });
        state.topLevelMenuItems = topLevelMenuItems;
        state.codeNameMapping = codeNameMapping;
        state.modSymbolMapping = modSymbolMapping;
      }
    },
    setFullMenuItem: (state, action: PayloadAction<ParsedMenuItem>) => {
      const item = action.payload;
      const itemId = item.itemId;
      state.fullMenuItems[itemId] = item;
    },
    updateSelectedMenuVersion: (state, { payload }: PayloadAction<string>) => {
      state.selectedMenuVersion = payload;
    },
    resetMenu: (state) => {
      state.topLevelMenuItems = {};
      state.modSymbolMapping = {};
      state.codeNameMapping = {};
      state.menuRes = undefined;
      state.persistentVoiceProps = {};
      state.categoriesWithTimePeriod = [];
      state.availableCategoryWithTimePeriod = [];
      state.alwaysAvailableCategories = [];
      state.unavailableItems = {};
    },
    updateMenuItems: (state, action) => {
      const {
        topLevelMenuItems,
        processedCategoryAndTimePeriodJSON,
        processedMenuJSON,
        processedModGroupsJSON,
        categoriesWithTimePeriod,
        availableCategoryWithTimePeriod,
        alwaysAvailableCategories,
        persistentVoiceProps,
        codeNameMapping,
        modSymbolMapping,
        unavailableItems,
      } = action.payload;
      state.topLevelMenuItems = topLevelMenuItems;
      logger.debug({
        message: 'Updating top level menu items',
        moreInfo: JSON.stringify(state.topLevelMenuItems),
      });
      state.modSymbolMapping = modSymbolMapping;
      state.codeNameMapping = codeNameMapping;
      state.menuRes = {
        ...processedCategoryAndTimePeriodJSON,
        ...processedMenuJSON,
        ...processedModGroupsJSON,
      };
      state.persistentVoiceProps = persistentVoiceProps;
      state.categoriesWithTimePeriod = categoriesWithTimePeriod;
      state.availableCategoryWithTimePeriod = availableCategoryWithTimePeriod;
      state.alwaysAvailableCategories = alwaysAvailableCategories;
      state.unavailableItems = unavailableItems;
    },
    resetVersions: (state) => {
      state.menuVersions = [];
      state.prodLiveVersion = {} as IMenuVersion;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMenuVersions.fulfilled,
      (state, { payload: { menuVersions, prodLiveVersion } }) => {
        state.menuVersions = menuVersions;
        state.prodLiveVersion = prodLiveVersion;
      }
    );
    builder.addCase(selectMenuVersion.fulfilled, (state, action) => {
      state.selectedMenuVersion = action.payload;
    });
  },
});

export const menuActions = menuSlice.actions;
export const {
  considerTimePeriods,
  setFullMenuItem,
  updateSelectedMenuVersion,
  updateMenuItems,
  resetMenu,
  resetVersions,
} = menuSlice.actions;

export default menuSlice.reducer;
