import { UserState } from '../reducers/userSlice';
import { TaskRouterLocalStorageInfo } from '../redux/features/taskRouter/taskRouter.props';
import logger from './logger';
import { nullOrUndefined } from './types';

const USER_STATE_KEY = 'user-info';
const USER_STATE_VERSION_KEY = 'user-info-version';
const AUTH_TOKEN_KEY = 'auth-token';
const TASKROUTER_AGENT_INFO = 'task-router-agent-info';

export const saveAuthToken = (authToken: string | nullOrUndefined) => {
  if (authToken) {
    logger.debug('Saving auth token to local storage');
    localStorage.setItem(AUTH_TOKEN_KEY, authToken);
  } else {
    logger.debug('Removing auth token from local storage');
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};

export const getAuthToken = (): string | undefined => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
  if (authToken && authToken !== '') {
    logger.debug('Restoring auth token from local storage');
    return authToken;
  }
  logger.debug('No auth token found in local storage');
  return undefined;
};

// Change this versionNumber whenever the format for user state changes so we know to delete the old data
const userStateVersion = '1.0.0';
export const getUserState = (): UserState | undefined => {
  // If called on server, localStorage doesn't exist
  if (typeof window === 'undefined') {
    logger.debug('Not loading user state b/c "window" is not defined');
    return undefined;
  }
  const serializedState = localStorage.getItem(USER_STATE_KEY);
  if (serializedState === null) {
    logger.debug('No previous user state found');
    return undefined;
  }
  const foundUserStateVersion = localStorage.getItem(USER_STATE_VERSION_KEY);
  if (!foundUserStateVersion || foundUserStateVersion !== userStateVersion) {
    logger.debug('User state version is out of date. Disregarding');
    localStorage.removeItem(USER_STATE_KEY);
    return undefined;
  }
  const userState = JSON.parse(serializedState);
  if (userState) {
    logger.debug('Restoring previous user state');
    return userState;
  }
  logger.debug('User state is falsey');
  return undefined;
};

export const saveUserState = (userState: UserState) => {
  logger.debug('Saving user state to local storage');
  const serializedState = JSON.stringify(userState);
  localStorage.setItem(USER_STATE_KEY, serializedState);
  localStorage.setItem(USER_STATE_VERSION_KEY, userStateVersion);
};

export const saveTaskRouterInfoInLS = (
  taskRouterInfo: TaskRouterLocalStorageInfo
) => {
  const serializedState = JSON.stringify(taskRouterInfo);
  localStorage.setItem(TASKROUTER_AGENT_INFO, serializedState);
};

export const getTaskRouterInfoFromLS = () => {
  const serializedState: any =
    localStorage.getItem(TASKROUTER_AGENT_INFO) || null;
  return JSON.parse(serializedState);
};

export const updateTaskRouterInfoInLS = (obj: any) => {
  const oldValue = getTaskRouterInfoFromLS();
  const serializedState = JSON.stringify({ ...oldValue, ...obj });
  localStorage.setItem(TASKROUTER_AGENT_INFO, serializedState);
};

export const removeTaskRouterInfoFromLS = () => {
  localStorage.removeItem(TASKROUTER_AGENT_INFO);
};

export const getLoggedInUserEmail = () => {
  const userInfo = localStorage.getItem(USER_STATE_KEY) || '';
  return userInfo ? JSON.parse(userInfo).userProfile?.email : '';
};

export const getTaskRouterAgentId = () => {
  const agentInfo = localStorage.getItem(TASKROUTER_AGENT_INFO) || '';
  return agentInfo ? JSON.parse(agentInfo).agentId : '';
};
