import { AppBar, Theme, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import packageJSON from '../../../package.json';
import { useAppSelector } from '../../app/hooks';
import { MenuStages, Routes } from '../../constants/enums';
import {
  selectFeatureFlagMenuVersion,
  selectFeatureFlagTaskRouter,
} from '../../redux/features/config/config.selector';
import { selectSessionId } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import { AgentStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { prodLiveVersionSelector } from '../../selectors/menu';
import {
  selectActiveRestaurantDetails,
  selectedStageSelector,
} from '../../selectors/restaurant';
import { RolePermissions } from '../../types/restaurant';
import {
  hasRoleAccess,
  selectedRestaurantAccessSelector,
} from '../../utils/restaurants';
import HeaderIndicators from '../HeaderIndicators';
import Logout from '../Login/Logout';
import MenuVersionPicker from '../MenuVersionPicker';
import MenuVersionStage from '../MenuVersionStage';
import RestaurantsContainer from '../RestaurantsContainer';
import TaskRouterHeaderInfo from '../TaskRouterHeaderInfo';

type HeaderProps = {
  isTaskRouterPage?: boolean;
  handleAgentAvailability: (agentStatus: AgentStatuses) => void;
  sendAgentEvent?: Function;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  restaurantPicker: {
    marginLeft: theme.spacing(2),
  },
  menuVersionPicker: {
    marginLeft: theme.spacing(2),
  },
  logout: {
    marginLeft: theme.spacing(2),
  },
}));

const Header: FC<HeaderProps> = ({
  handleAgentAvailability,
  sendAgentEvent,
}) => {
  const location = useLocation();
  const taskRouterFeatureFlag = useAppSelector(selectFeatureFlagTaskRouter);
  const currentTaskId = useAppSelector(selectCurrentTask);

  const isTaskRouterPage =
    taskRouterFeatureFlag && location.pathname.indexOf(Routes.taskRouter) >= 0;

  const classes = useStyles();
  const title = `Voice Terminal v${packageJSON.version}`;
  const { restaurantCode, primaryRestaurantCode } = useSelector(
    selectActiveRestaurantDetails
  );
  const selectedStage = useSelector(selectedStageSelector);
  const sessionId = useAppSelector(selectSessionId) || '';
  const { commitId } = useSelector(prodLiveVersionSelector) || {};
  const selectedRestaurantAccessLevel = useSelector(
    selectedRestaurantAccessSelector
  );
  const hasMenuVersionAccess = hasRoleAccess(
    RolePermissions.RestaurantManager,
    selectedRestaurantAccessLevel
  );
  const FEATURE_FLAG_MENU_VERSION = useSelector(selectFeatureFlagMenuVersion);
  const displayMenuVersion =
    FEATURE_FLAG_MENU_VERSION &&
    hasMenuVersionAccess &&
    restaurantCode &&
    !currentTaskId &&
    !primaryRestaurantCode;

  return (
    <AppBar position="static">
      <Toolbar>
        <img src="/presto_logo.svg" alt="Presto Logo" />
        <div className={classes.title}>
          <Typography>{title}</Typography>
          <Typography>
            {!isTaskRouterPage && restaurantCode ? sessionId : ''}
          </Typography>
        </div>
        {!isTaskRouterPage && (
          <>
            <HeaderIndicators />
            {displayMenuVersion && (
              <div className={classes.restaurantPicker}>
                <MenuVersionStage />
              </div>
            )}
            <div className={classes.restaurantPicker}>
              <RestaurantsContainer />
            </div>

            {displayMenuVersion && (
              <div className={classes.restaurantPicker}>
                {selectedStage === MenuStages.PLAYGROUND ? (
                  <MenuVersionPicker />
                ) : (
                  <>{commitId ? <span>{`Version ${commitId}`}</span> : null}</>
                )}
              </div>
            )}
          </>
        )}
        <div className={classes.logout}>
          <Logout sendAgentEvent={sendAgentEvent} />
        </div>
        {isTaskRouterPage && (
          <TaskRouterHeaderInfo
            handleAgentAvailability={handleAgentAvailability}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
