import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../app/hooks';
import useIntervention from '../../hooks/intervention/useIntervention.hooks';
import useUnsupervisedAI from '../../hooks/unsupervisedAI/useUnsupervisedAI.hooks';
import { selectIsWebSocketConnected } from '../../selectors/message';
import Colors from '../../utils/color';
import { AutocompleteComp } from './AutocompleteComp';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '96px',
    overflow: 'hidden',
  },
  wrapForm: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: `${theme.spacing(2.5)} ${theme.spacing(3.5)}`,
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
}));

export const OmniBar = () => {
  const classes = useStyles();
  const isWebSocketConnected = useAppSelector(selectIsWebSocketConnected);
  const { showUnsupervisedAIOverlay } = useUnsupervisedAI();
  const { isStaffInterventionHappening } = useIntervention();

  const isDisabledMode = useMemo(() => {
    if (showUnsupervisedAIOverlay) return true;
    else if (isWebSocketConnected) {
      return !!isStaffInterventionHappening;
    }
    return true;
  }, [
    showUnsupervisedAIOverlay,
    isWebSocketConnected,
    isStaffInterventionHappening,
  ]);

  return (
    <div
      data-testid="omnibar"
      className={classes.container}
      style={
        isStaffInterventionHappening
          ? { backgroundColor: Colors.torchRed }
          : undefined
      }
    >
      <form className={classes.wrapForm} noValidate autoComplete="off">
        <AutocompleteComp disabled={isDisabledMode} />
      </form>
    </div>
  );
};
