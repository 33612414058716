import { Grid, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ItemBuilder from '../../features/item-builder/ItemBuilder';
import { Messaging } from '../../features/messaging/Messaging';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import OrderReview from '../../features/order-review/OrderReview';
import { considerTimePeriods } from '../../reducers/menuSlice';
import { selectCartModality } from '../../selectors/cart';
import {
  selectActiveRestaurantTimezone,
  selectedStageSelector,
} from '../../selectors/restaurant';
import { ICartItemRefs, SetCartItemRef } from '../../types';
import Colors from '../../utils/color';
import { TIME_PERIOD_CHECK_INTERVAL } from '../../utils/constants';
import AIStatusBar from '../AIStatusBar';
import { VolumeSlider } from '../Audio/VolumeSlider';
import RightBar from '../Layout/RightBar';
import { OmniBar } from '../OmniBar/OmniBar';
import TimeBasedMenuNotification from '../TimeBasedMenuNotification';

interface IMainContentStyleProps {
  showAIStatus: boolean;
}

const useStyles = makeStyles<Theme, IMainContentStyleProps>(({ spacing }) => ({
  mainContentClass: {
    height: '100%',
    display: 'flex',
    backgroundColor: Colors.porcelain,
  },
  transcriptContainerClass: {
    display: 'flex',
    maxHeight: '100%',
    minWidth: '300px',
    maxWidth: '360px',
  },
  middleSectionClass: {
    flex: 1,
    width: '50%',
  },
  flexHeightContainerClass: {
    height: ({ showAIStatus }) =>
      showAIStatus
        ? `calc(100% - ${spacing(18)})`
        : `calc(100% - ${spacing(12)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `${spacing(0.125)} solid ${Colors.alto}`,
    borderTop: 'none',
  },
}));

const MainContent = () => {
  const { isAIActive, isAIAutoMode } = useAIMessaging();
  const showAIStatus = isAIActive && isAIAutoMode;
  const {
    mainContentClass,
    transcriptContainerClass,
    middleSectionClass,
    flexHeightContainerClass,
  } = useStyles({ showAIStatus });
  const dispatch = useAppDispatch();
  const timezone = useAppSelector(selectActiveRestaurantTimezone);
  const stage = useAppSelector(selectedStageSelector);
  const modality = useAppSelector(selectCartModality);
  const cartItemRefs = useRef<ICartItemRefs>({});
  const setCartItemRef: SetCartItemRef = (
    cartItemId: number,
    ref: HTMLDivElement | null
  ) => {
    cartItemRefs.current[cartItemId] = ref;
  };

  useEffect(() => {
    if (timezone) {
      const timer = setInterval(() => {
        dispatch(considerTimePeriods({ modality, timezone, stage }));
      }, TIME_PERIOD_CHECK_INTERVAL);
      return () => {
        clearInterval(timer);
      };
    }
  }, [timezone, dispatch, modality, stage]);

  return (
    <div id="main-content" className={mainContentClass}>
      <Grid item className={transcriptContainerClass}>
        <Stack>
          <VolumeSlider />
          <Messaging />
        </Stack>
      </Grid>

      <Grid item className={middleSectionClass}>
        <div className={flexHeightContainerClass}>
          <TimeBasedMenuNotification />
          <ItemBuilder setCartItemRef={setCartItemRef} />
        </div>
        {showAIStatus && <AIStatusBar />}
        <OmniBar />
      </Grid>

      <RightBar>
        <OrderReview />
      </RightBar>
    </div>
  );
};

export default MainContent;
