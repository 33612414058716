import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC, default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import useTaskRouterMenuHandlers from '../../hooks/taskRouterHandlers/useTaskRouterMenuHandlers.hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import {
  resetSelectedRestaurantDetails,
  selectRestaurant,
} from '../../reducers/restaurantSlice';
import { startLoading } from '../../redux/features/loading/loading.slice';
import {
  selectCurrentTask,
  selectCurrentTaskRestaurantCode,
  selectCurrentTaskRestaurantName,
} from '../../redux/features/taskRouter/taskRouter.selector';
import { selectRestaurantsById } from '../../selectors/restaurant';
import RestaurantPicker from '../RestaurantPicker';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  name: {
    fontWeight: '500 !important',
    fontSize: '0.875rem !important',
    lineHeight: '1.75 !important',
    textTransform: 'uppercase',
    minWidth: spacing(8),
    padding: `${spacing(0.75)} ${spacing(2)}`,
  },
}));

const RestaurantsContainer: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPlaying = useAppSelector((state) => state.messages.isPlaying);
  const currentTaskId = useAppSelector(selectCurrentTask);
  const {
    taskRouterMenuLoadFailureCallback,
    taskRouterMenuLoadSuccessCallback,
  } = useTaskRouterMenuHandlers();
  const currentTaskRestaurantCode = useAppSelector(
    selectCurrentTaskRestaurantCode
  );
  const currentTaskRestaurantName = useAppSelector(
    selectCurrentTaskRestaurantName
  );
  const restaurantsById = useAppSelector(selectRestaurantsById);

  const targetRestaurant = useMemo(() => {
    if (currentTaskRestaurantCode) {
      if (Object.keys(restaurantsById).length) {
        return Object.values(restaurantsById)?.filter(
          (restaurant) =>
            restaurant.restaurantCode === currentTaskRestaurantCode
        )?.[0];
      }
      return {
        restaurantCode: currentTaskRestaurantCode,
        restaurantName: currentTaskRestaurantName,
      };
    }
  }, [restaurantsById, currentTaskRestaurantCode, currentTaskRestaurantName]);

  const selectUpdatedRestaurant = ({
    restaurantCode,
    startLoadingIndicator,
  }: {
    restaurantCode: string;
    startLoadingIndicator: boolean;
  }) => {
    if (startLoadingIndicator) {
      dispatch(startLoading({}));
    }
    dispatch(
      selectRestaurant({
        restaurantCode: restaurantCode,
        taskRouterMenuLoadFailureCallback,
        taskRouterMenuLoadSuccessCallback,
      })
    );

    if (!isPlaying) {
      dispatch(messagingActions.setIsPlaying(true));
    }
  };

  const handleRestaurantSelect = (restaurantCode: string) => {
    if (currentTaskId) {
      selectUpdatedRestaurant({
        restaurantCode,
        startLoadingIndicator: false,
      });
      return;
    }
    dispatch(resetSelectedRestaurantDetails());
    selectUpdatedRestaurant({
      restaurantCode,
      startLoadingIndicator: true,
    });
  };

  useEffect(() => {
    if (targetRestaurant) {
      handleRestaurantSelect(targetRestaurant.restaurantCode);
    }
  }, [targetRestaurant]);

  return (
    <div id="restaurants-container">
      {targetRestaurant ? (
        <Typography className={classes.name}>
          {targetRestaurant?.restaurantName}
        </Typography>
      ) : (
        <RestaurantPicker handleRestaurantSelect={handleRestaurantSelect} />
      )}
    </div>
  );
};

export default RestaurantsContainer;
