import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  TaskStatuses,
  TaskStatusesInRestaurantPage,
} from './taskRouter.constants';

const selectTaskRouter = (state: RootState) => state.taskRouter;
const selectAgentStatus = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.agentStatus
);
const selectTasksById = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.tasksById
);
const selectTasksOrder = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.tasksOrder
);
const selectWSConnectionMessage = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.wsConnectionMessage
);
const selectTasksOffered = createSelector(
  selectTasksOrder,
  selectTasksById,
  (tasksOrder, tasksById) =>
    tasksOrder.filter((id) => tasksById[id].event === TaskStatuses.offered)
);
const selectActiveTaskOfferedId = createSelector(
  selectTasksOffered,
  (tasksOffered) => tasksOffered?.[0]
);
const selectCurrentTask = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.currentTaskId
);
const selectCurrentTaskRestaurantCode = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.currentTaskRestaurantCode
);
const selectCurrentTaskRestaurantName = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.currentTaskRestaurantName
);
const selectCurrentTaskStatus = createSelector(
  selectCurrentTask,
  selectTasksById,
  (currentTaskId, tasksById) => tasksById?.[currentTaskId]?.event
);
const selectTaskAssignedSessionId = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.taskAssignedSessionId
);
const selectTaskAssignedLaneId = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.taskAssignedLaneId
);
const selectTasksAssigned = createSelector(
  selectTasksOrder,
  selectTasksById,
  (tasksOrder, tasksById) =>
    tasksOrder.filter((id) => tasksById[id].event === TaskStatuses.assigned)
);
const selectAssignedTaskId = createSelector(
  selectTasksAssigned,
  (tasksAssigned) => tasksAssigned?.[0]
);

const selectTaskStatusChanged = createSelector(
  selectTasksById,
  selectCurrentTask,
  (tasksById, currentTaskId) => {
    if (tasksById && currentTaskId) {
      const task = tasksById[currentTaskId];
      if (
        task &&
        [
          TaskStatuses.accepted,
          TaskStatuses.invalid,
          TaskStatuses.invalidAck,
          ...TaskStatusesInRestaurantPage,
        ].includes(task?.event)
      ) {
        return task;
      }
    }
  }
);

const selectNavigationViaTaskRouter = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.navigationViaTaskRouter
);

const selectAgentInSession = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.agentInSession
);

export {
  selectActiveTaskOfferedId,
  selectAgentInSession,
  selectAgentStatus,
  selectAssignedTaskId,
  selectCurrentTask,
  selectCurrentTaskRestaurantCode,
  selectCurrentTaskRestaurantName,
  selectCurrentTaskStatus,
  selectNavigationViaTaskRouter,
  selectTaskAssignedLaneId,
  selectTaskAssignedSessionId,
  selectTaskRouter,
  selectTaskStatusChanged,
  selectTasksAssigned,
  selectTasksById,
  selectTasksOffered,
  selectTasksOrder,
  selectWSConnectionMessage,
};
