import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Routes } from '../../constants/enums';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import {
  resetSelectedRestaurantDetails,
  updateAIEnabledStatus,
} from '../../reducers/restaurantSlice';
import { resetAI, toggleReasonsDialog } from '../../redux/features/ai/ai.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import {
  selectCurrentTask,
  selectCurrentTaskStatus,
} from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import { OrderActionArgs } from '../../types';
import logger from '../../utils/logger';
import useTaskRouterInformativeMessages from './useTaskRouterInformativeMessages.hooks';

const useTaskRouterHandlers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTaskId = useAppSelector(selectCurrentTask);
  const currentTaskStatus = useAppSelector(selectCurrentTaskStatus);
  const { isAIActive, isAIAutoMode } = useAIMessaging();
  const { populateInformativeAlerts } = useTaskRouterInformativeMessages();

  const navigateToTaskRouterPage = () => {
    populateInformativeAlerts();
    dispatch(resetSelectedRestaurantDetails());
    dispatch(updateAIEnabledStatus(false));
    dispatch(messagingActions.closeConnection());
    dispatch(messagingActions.closeAudioWSConnection());
    dispatch(resetAI());
    logger.debug({
      message:
        'In navigateToTaskRouterPage: Before navigating to task-router page',
      taskId: currentTaskId,
      isTR: true,
    });
    navigate(location.state?.from || '/' + Routes.taskRouter);
  };

  const sendTaskStatus = (event: TaskStatuses, args?: OrderActionArgs) => {
    const {
      isCancelOrder,
      isFinishOrder,
      isStaffIntervention,
      isRestaurantStaffInterventionClicked,
    } = args || {};
    if (
      currentTaskId &&
      (isCancelOrder ||
        isFinishOrder ||
        isStaffIntervention ||
        isRestaurantStaffInterventionClicked)
    ) {
      dispatch(
        updateTaskStatus({
          taskId: currentTaskId,
          event,
        })
      );
    }
  };

  const endTask = () => {
    if (isAIActive && !isAIAutoMode) {
      dispatch(toggleReasonsDialog(true));
    } else if (currentTaskId) {
      navigateToTaskRouterPage();
    }
  };

  return {
    sendTaskStatus,
    isCurrentTaskFromTaskRouter: !!currentTaskId,
    currentTaskStatus,
    endTask,
    navigateToTaskRouterPage,
  };
};

export default useTaskRouterHandlers;
